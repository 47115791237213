.news-detail-2
	.briefcontent
		@apply text-clamp-16 font-normal text-grey-800 leading-normal
		*
			@apply text-clamp-16 font-normal text-grey-800
	.gap-wrap
		time
			@apply text-primary-700 font-normal text-clamp-14 
	.time-social
		@apply my-6
		@screen xl
			@apply my-8
	.social-wrap
		a
			@apply center-item text-clamp-20 text-primary-700 cursor-pointer
			&:not(:last-child)
				@apply mr-5
			&:hover
				em
					transform: translateY(-3px)
			em
				@apply text-clamp-20 text-primary-700 transition
			span
				@apply inline-block ml-1 text-clamp-14 text-primary-700
			&.share-copy-link
				&:hover
					em
						transform: none
					span
						@apply underline
				em
					font-size: 12px
.other-news-wrap
	.headline
		@apply text-clamp-24 pb-4 relative mb-4
		border-bottom: 1px solid #EAF2F6
		@media screen and ( max-width: 1024px)
			@apply pb-6 mb-6
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-[-1px] z-10  transition w-[40px] h-[4px]
			background: #8CC241
		*
			@apply text-clamp-24 
	.news-figure
		border-radius: 10px
		time
			font-size: 10px
		.img
			a
				height: 205px
		.title
			@apply text-clamp-18
		.desc
			font-size: 12px
			+line(3)
			*
				font-size: 12px
				+line(3)
		.txt
			@apply p-3
			@screen xl
				@apply px-4
	.news-half
		@apply py-4 m-0
		&:not(:last-child)
			border-bottom: 1px solid #EAF2F6
		.img
			width: 200px
			max-width: 200px
			min-width: 200px
			@media screen and ( min-width: 1200px) and ( max-width: 1400px)
				width: 150px
				max-width: 150px
				min-width: 150px
			a
				height: 120px
		.txt
			@apply pl-3 w-full
		.heading
			a
				@apply text-clamp-16 no-underline transition font-bold
		time
			@apply text-clamp-14
		&:hover
			.heading
				a
					@apply underline