.zone-category-nav
	@apply bg-primary-700
	@apply overflow-x-auto
	ul
		@apply start-item   gap-5
		justify-content: space-between
		@media screen and ( max-width: 1200px)
			@apply whitespace-nowrap pb-1
		@media screen and ( max-width: 1200px)
			justify-content: space-between


	li
		&.active,&:hover
			a
				background: #8CC241
		&:first-child
			@apply pl-0
	a
		@apply font-medium
.side-mobile-nav
	li
		&:not(:last-child)
			border-bottom: 1px solid #D5EDF8
		&.active
			a
				@apply font-bold text-green-400