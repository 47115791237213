.news-detail
	.social-wrap
		a
			@apply transition
			&:not(:last-child)
				@apply mr-5
			&:hover
				transform: translateY(-3px)
	.iframe-view-file
		+iframe-ratio(9,16)
.news-detail-page,.product-detail-page
	header
		@apply bg-white h-[100px]
	main
		padding-top: 100px
	.top-banner
		@apply hidden
	.banner-breadcrumb
		.global-breadcrumb
			@apply m-0 z-50 w-full center-item border-transparent  bg-grey-50
			position: static !important
			transform: none !important
			left: unset !important
			min-height: 32px
			.container
				@apply flex flex-col items-start
				justify-content: center
				min-height: 32px
			h2
				@apply hidden
			.Module
				@apply order-2
			ol
				@apply order-2 my-0
				li
					&::after
						@apply text-grey-500
						background: #818181 !important
						opacity: 1 !important
					&.active
						a,span
							@apply text-grey-500
					&:first-child
						span,a
							@apply text-grey-500
						&:after
							@apply text-grey-500
						&:before
							@apply text-grey-500
					&:last-child
						@apply text-grey-500
					a
						@apply text-grey-500
						span
							@apply text-grey-500
.other-news
	.news-item
		@apply bg-white
		.txt
			@apply bg-white

.other-document
	.item-document
		@apply h-full
		.txt
			@apply h-full
	.icon
		height: 32px
		width: 32px
		min-width: 32px
		img
			width: auto
			height: 100%
			object-fit: contain
	time
		font-size: 12px
	.item-document
		align-items: flex-start
	.swiper-slide
		@apply h-auto