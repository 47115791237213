.product-list
	.filter-wrap
		@apply justify-start
		.filter-item
			@media screen and ( max-width: 1024px)
				@apply text-left items-start
				label
					@apply text-clamp-20

			&:first-child
				@screen xl
					@apply mr-10
	.product-zone-nav
		.custom-select
			@apply  text-white 
			select
				@apply text-white bg-primary-700 w-full h-full rounded overflow-hidden
				option
					@apply text-white
.product-item
	.img 
		a
			+img-ratio(1,1,contain)
	.headline
		a
			@media screen and ( max-width: 576px)
				@apply text-clamp-18
	&:hover
		.headline
			a
				@apply text-primary-700