.swiper-zone-nav
	@apply -mx-1
	a
		@apply center-item h-[50px] px-5 overflow-hidden rounded-2 bg-white text-clamp-20 transition text-primary-700
	.swiper
		padding: 0 !important
		margin: 0 !important
	.swiper-slide
		width: fit-content !important
		&:not(:first-child)
			.item
				@apply ml-3
		&.active,&:hover
			a
				@apply text-white bg-primary-700
	.prev,.next
		@apply w-[20px] h-[20px] min-w-[20px] border-transparent
		em
			@apply text-grey-500 text-clamp-16
		&:hover
			@apply bg-transparent
			em
				@apply text-primary-700
	.swiper-nav
		@apply p-0
	.prev
		@apply absolute-y !#{important}
		left: -30px
	.next
		@apply absolute-y !#{important}
		right: -30px
.wrap-search
	@apply relative
	input[type='text']
		@apply h-[56px] rounded-full bg-primary-EAF2F6 px-10 pr-15 w-full text-clamp-16 text-grey-950
		&::placeholder
			@apply text-grey-500 text-clamp-16
	&::before
		content: '\f002'
		@apply  absolute-y right-[4px] pointer-events-none  z-10  transition w-full h-full font-Awesome6 text-clamp-20  text-white overflow-hidden center-item rounded-full
		background: #8CC241
		width: 48px
		height: 48px

	button,a
		@apply absolute-y  h-full right-0 z-30 center-item  px-5
		em
			@apply text-green-400 text-clamp-20
.document-item
	.btn-tertiary
		em
			@apply ml-0
		span
			@apply whitespace-nowrap
		&:hover
			em
				@apply transform-none
			span
				@apply underline 
	&:hover
		.headline
			a
				@apply text-primary-700