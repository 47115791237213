.about-1
	.item
		@apply justify-between
	.box
		p
			@apply block text-white text-clamp-20 mb-0
			*
				@apply block text-white text-clamp-20
			strong,a
				@apply text-clamp-24 font-bold pt-1
				word-break: break-word
				*
					@apply text-clamp-24
	.zone-desc
		*
			@apply text-clamp-18
	.information
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: #0E538FBF
.about-2
	@apply overflow-hidden
	.block-title
		@apply mb-3
	.img-wrap
		@apply pointer-events-auto
		a
			+img-ratio(700,940)
	.img
		a
			+img-ratio(480,640)
	.row,.col
		@apply mb-0 
	.first
		@screen lg
			@apply pt-15
		.scrollbar-wrap
			max-height: r(320px)
	.parallax-item
		.img-wrap
			@screen lg
				background-attachment: fixed
				img
					@apply hidden 
			@media screen and ( max-width: 1024px)
				background: none !important
	.second
		.box-wrap
			@apply py-10
			@media screen and ( max-width: 1024px)
				@apply col-ver
			@screen lg
				@apply py-15
			&:nth-child(even)
				@apply bg-grey-50 bg-opacity-50
				.row
					@screen lg
						@apply flex-row-reverse
				.txt
					@screen lg
						@apply pr-0 pl-10
		.col
			@media screen and ( max-width: 1024px)
				@apply order-2
				&:nth-child(2)
					@apply order-1
		.scrollbar-wrap
			max-height: r(240px)
			@screen lg
				max-height: r(320px)
		.txt
			@media screen and ( max-width: 1024px)
				@apply pt-10
			@screen lg
				@apply pr-10
	.block-wrap
		@apply relative 
		@media screen and ( max-width: 1024px)
			@apply col-ver
		&:first-child
			@apply mb-10
			@screen lg
				@apply mb-15
		&:nth-child(2)
			@apply bg-primary-700 text-white
			*
				@apply text-white
		.txt
			@apply pointer-events-auto py-10
			@screen lg
				@apply pr-12 py-0
		.container-fluid
			@apply p-0
			@media screen and ( max-width: 1024px)
				@apply order-1
		.container
			@apply pointer-events-none
			@media screen and ( max-width: 1024px)
				@apply order-2
			@screen lg
				@apply absolute-center
		&:nth-child(even)
			.row
				@screen lg
					@apply flex-row-reverse
			.txt
				@screen lg
					@apply pl-12 pr-0
			

.about-3
	.img
		a
			background: #F9F9F9

.about-4
	.bg-wrap
		border: 1px solid #DDEFFA
		margin: 0 !important
		box-shadow: 4px 4px 32px 16px #00427A0A
	.img
		a
			@apply center-item
			height: r(100px)
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain