.about-people
	.swiper-main
		@apply mt-10
		.swiper
			@apply -m-2 p-2
	.swiper-thumb
		.swiper-slide
			height: auto !important
			@apply w-1/4
			@media screen and ( max-width: 1024px)
				@apply w-1/3
			@media screen and ( max-width: 768px)
				@apply w-1/2
			@media screen and ( max-width: 460px)
				@apply w-full
		.swiper-slide-thumb-active
			.headline 
				@apply text-primary-700
	
	.profile-item
		@apply h-full
.profile-item
	&::after
		content: ''
		@apply block absolute-x pointer-events-none bottom-0 z-10  transition w-[50px] h-[2px] bg-primary-700 
	.img
		a
			@apply center-item mx-auto mb-5 overflow-hidden rounded-full
			max-width: r(280px)
			width: r(280px)
			height: r(280px)
	.headline
		@apply mb-3 text-grey-950
		+clamp(20px,24px,1.3,2)
	.txt
		@apply relative pb-3
	.desc
	
.profile-info
	box-shadow: 0px 4px 16px 0px #00000014
	@apply rounded-4 overflow-hidden p-8
	@screen xl
		padding: r(60px) r(40px)
	.position
		@apply text-clamp-20 text-primary-700 relative pb-4 mb-6
		@screen xl
			@apply mb-8
		&::after
			content: ''
			@apply block absolute left-0 bottom-0 pointer-events-none bottom-0 z-10  transition w-[50px] h-[2px] bg-primary-700 
	.btn-tertiary
		span,em
			@apply text-primary-500