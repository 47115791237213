.title-block
	@apply 	gap-wrap
	@media screen and ( max-width: 1024px)
		@apply flex-col items-center text-center
		.zone-title
			@apply mb-5
.title-wrapper
	@apply 	gap-wrap  relative mb-8
	@screen xl
		@apply mb-12
	.zone-title
		@apply mb-0
	@media screen and ( max-width: 1024px)
		@apply flex-col items-center text-center
.report-item
	.icon
		em
			@apply text-clamp-32 font-thin text-primary-700 
	.title
		@apply text-clamp-18 font-semibold transition
.report-item-2
	.img
		a
			+img-ratio(430,310)
	.btn
		span,em
			@apply text-primary-700 font-semibold text-clamp-16
		em
			@apply font-light
		&:hover
			em
				@apply  transform-none
	.heading
		a
			+clamp(17px,20px,1.3,2)
	&:hover
		.heading
			a
				@apply underline
.report-item-3
	@apply  overflow-hidden gap-wrap 
	align-items: flex-start
	@media screen and ( max-width: 576px)
		@apply flex-col items-start

	.title
		&:hover
			@apply underline text-primary-900 block
	.btn-wrap
		@apply flex flex-col items-center justify-center h-full
		@media screen and ( max-width: 576px)
			@apply pt-4
		@screen sm
			@apply pl-4
.cong-bo-thong-tin
	@apply bg-primary-EAF2F6 bg-opacity-25
	.report-item-3
		@apply h-full
		.btn-wrap
			@apply flex flex-col items-center justify-center h-full
.invest-1
	@apply pb-0
	#app
		@apply overflow-hidden
	.main-content
		@apply overflow-hidden
	.iframe-wrap
		display: block
		height: r(760px)
		@apply overflow-hidden
		text-align: center
		width: 100%
		@media screen and ( max-width: 1024px)
			height: 400px
		@media screen and ( max-width: 576px)
			height: 1300px
		iframe,img
			@apply inline-block w-full h-full
		img
			@apply hidden
			
	.title-block
		.headline
			@apply font-semibold
	.title
		@apply text-clamp-16 font-semibold text-grey-950
		*
			@apply text-clamp-16 font-semibold text-grey-950
	.list
		li
			@apply py-5 border-b border-b-grey-100
.invest-2
	@apply bg-primary-EAF2F6 bg-opacity-25
	.title-wrapper
		@apply 	 pb-8 
		@screen xl
			@apply pb-6
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  bg-primary-700 transition w-full h-[1px]

.invest-3
	.four-swiper
		.swiper
			@apply -m-3 p-3 -mx-6 px-2
			@media screen and ( max-width: 1200px)
				@apply -mx-5
			.swiper-slide
				@apply px-3 h-auto
				@media screen and ( max-width: 1200px)
					padding: 0 10px
	.report-item-2
		@apply h-full
.invest-4
	.report-item-3
		@apply h-full
.invest-5
	.report-item-3
		@apply h-full
	.filter-select
		@apply bg-transparent border-white
		select
			@apply bg-transparent text-white
		&::after
			@apply text-white
.invest-6
	.block-zone-filter
		@apply pt-6
	.title-wrapper
		@apply mb-0
	.filter-year
		.filter-select
			select
				@apply bg-transparent
.report-item-4
	@apply py-7 items-start
	&:not(:last-child)
		@apply border-b border-b-primary-700
	.btn-wrap
		@screen xl
			@apply pl-10
.invest-7
	.swiper
		@apply -m-3 p-3 -mx-6 px-2
		@media screen and ( max-width: 1200px)
			@apply -mx-5
		.swiper-slide
			@apply px-3
			@media screen and ( max-width: 1200px)
				padding: 0 10px
	
	.filter-year 
		@apply pt-0 mb-8
		.filter-select
			@apply ml-auto mr-0
	.block-zone-filter
		.filter-year
			@media screen and ( max-width: 576px)
				@apply pt-0
	.img
		a
			+img-ratio(536,680)
	
	.swiper-nav
		@apply start-item
		.prev
			@apply mr-3
	.prev,.next
		@apply static rounded-full overflow-hidden
		transform: none !important
		left: unset !important
		right: unset !important
		top: unset !important
		bottom: unset !important
		width: r(48px)
		min-width: r(48px)
		height: r(48px)
		em
			@apply text-clamp-16
.news-display
	.news-figure
		&:first-child
			@apply mb-6
	.top-row
		margin: 0 -8px -30px -8px
		>.col
			margin-bottom: 30px
			padding: 0 8px
.news-figure
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
		background: linear-gradient(153.43deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 83.33%)
	&::before
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full opacity-0
		background: linear-gradient(153.43deg, rgba(#22356B,.85 ) 0%, rgba(14, 191, 241, 0.8) 83.33%)
	&:hover
		&::after
			@apply opacity-0
		&::before
			@apply opacity-100
	.img
		a
			@apply center-item
			height: r(330px)
			width: 100%
			img
				width: 100%
				height: 100%
				object-fit: cover
	.desc
		@apply text-clamp-16 
		+line(4)
		*
			@apply text-clamp-16
			+line(4)
	.txt
		@apply pointer-events-none
	&:hover
		.title 
			@apply underline
.news-half
	@apply gap-wrap
	align-items: flex-start
	&:not(:last-child)
		@apply mb-3
	.heading
		@apply mb-1
		a
			@apply text-primary-700 underline
			text-decoration-thickness: .1px !important
	time
		@apply text-clamp-12 font-normal
	.img,.txt
		@apply w-1/2
	&:hover
		.img
			&::before
				@apply opacity-100
	.img
		@apply relative
		&::before
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full opacity-0
			background: linear-gradient(180deg, rgba(#0E538F ,.8 ) 0%, rgba(#51AEFF,.8 ) 100%)
		a
			@apply center-item
			height: r(160px)
			width: 100%
			img
				width: 100%
				height: 100%
				object-fit: cover
	.txt
		@apply pl-4
.news-box
	.img
		a
			@apply center-item overflow-hidden rounded-4
			height: r(214px)
			width: 100%
			img
				width: 100%
				height: 100%
				object-fit: cover
	.txt
		@apply py-3
	time
		@apply text-clamp-12 font-normal text-primary-700 mb-[2px]
	.heading
		@apply mb-1
		a
			@apply  text-primary-700 font-bold
			+clamp(20px,24px,1.3,2)
	
	.desc
		@apply text-primary-700 text-clamp-14 font-normal
		+line(2)
		*
			@apply text-primary-700 text-clamp-14 font-normal
			+line(2)
	&:hover
		.heading
			a
				@apply underline
.news-display-page
	.news-display
		@apply pb-0
	.newspager
		@apply pb-15 
.document-list  
	&.bao-cao-thuong-nien
		.report-item-2 
			@apply h-full
.banner-page
	@apply relative overflow-hidden
	.img
		a
			@apply relative
			&::after
				content: ''
				@apply block absolute-center pointer-events-none z-10  transition w-full h-full
				background: #00000066
			@media screen and ( max-width: 1024px)
				+img-ratio(16,9)
			@screen lg
				+img-ratio(480,1920)
	.container
		top: r(80px)
	.zone-title
		@apply mb-0
	.desc
		@apply text-clamp-18 font-semibold text-white my-5
		@screen xl
			@apply   my-8
		*
			@apply text-clamp-18 font-semibold text-white