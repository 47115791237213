.product-detail
	.block-wrap
		@screen xl
			@apply px-15
	.img
		a
			+img-ratio(1,1,contain)
	.btn
		@apply w-full
		&.white
			@apply border-transparent
		em
			@apply mr-3
	.information
		li
			list-style: none
			@apply py-4 text-clamp-18 
			*
				@apply text-clamp-18 
			p
				@apply mb-0
			>div
				@apply block
			&:not(:last-child)
				@apply border-b border-b-grey-200
			&:first-child
				@apply pt-0
				>div
					>div
						&:first-child
							@apply text-green-400
			>div
				>div
					&:first-child
						@apply text-primary-700 mb-3 block font-normal
	.tags
		@apply pt-5 start-item flex-wrap  gap-1
		.tags__label
			@apply font-bold text-clamp-14 flex items-center h-auto w-full mb-2
		li
			@apply border-transparent 
		a
			@apply center-item px-3 py-1 overflow-hidden rounded-2 bg-primary-200 text-grey-900 border-primary-200 border transition text-clamp-14
			height: 32px
			&:hover
				@apply bg-white text-primary-700 
	.information
		table
			tr
				td
					@apply py-4
				&:first-child
					td
						@apply pt-0
				&:last-child
					td
						@apply pb-0
				&:not(:last-child)
					td
						@apply border-b border-b-grey-200
.product-information
	.tab-nav
		@apply justify-start
		ul
			@apply justify-start
		a
			height: 50px
			@apply px-8

	.bg-wrap
		box-shadow: 0px 4px 16px 0px #00000014
		@screen xl
			padding: r(32px) r(60px)
	.fullcontent
		h3
			@apply text-primary-700
.product-other-item
	.headline
		a
			@apply text-grey-950
	.img
		a
			+img-ratio(1,1)
	&:hover
		.headline
			a
				@apply text-primary-700
		