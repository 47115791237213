.news-report
	.document-item
		@screen xl
			padding: r(28px) 0 r(20px)
.news-list-2
	@apply bg-grey-50 bg-opacity-60
	.news-item
		.txt
			@apply bg-white

.social-list
	.item
		@apply bg-primary-50
	.title
		@apply start-item mb-4
		em
			@apply text-clamp-36 mr-4 text-primary-700
	ul
		@apply start-item flex-wrap
		li
			@apply py-2
			@screen md
				@apply  px-5  
			&:not(:last-child)
				@screen md
					@apply border-r border-r-grey-200
			&:first-child
				@apply pl-0
	a
		@apply text-clamp-20 
		&:hover
			@apply underline
.gallery-2
	.media-item
		.txt
			@apply bg-white
.news-banner
	.txt
		@media screen and ( max-width: 1024px)
			@apply w-full max-w-full py-10 bg-primary-700 border-b border-b-white
		
	.img
		a
			+img-ratio(540,1920)
	
	li
		@apply text-clamp-18 text-white
		*
			@apply text-clamp-18 text-white
		em
			@apply text-clamp-20 mr-3
		&:not(:last-child)
				@apply mb-4
	