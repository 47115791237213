.recruit-1
	.img
		a
			@apply center-item
			img
				width: auto
				height: 100%
				object-fit: contain
.recruit-2
	.btn-primary
		@apply border-transparent !#{important}
.recruit-3
	.item
		box-shadow: 0px 4px 16px 0px #00000014
		background: var(--color-code)
	.icon
		display: flex
		height: r(60px)
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain
.recruit-list
	@apply bg-grey-50 bg-opacity-50
	table
		@apply rounded-2 overflow-hidden w-full
		thead
			th
				@apply bg-primary-700 font-bold text-white text-center py-4 px-7 text-clamp-20
				&:nth-child(2)
					@apply text-left pl-0
				&:first-child
					width: 90px
				&:last-child
					width: 200px
					
					
		tbody
			td
				@apply text-clamp-18 px-7 py-5 bg-white
				*
					@apply text-clamp-18
				&:first-child
					width: 90px
				&:nth-child(2)
					@apply text-left pl-0
				&:last-child
					width: 200px
				a
					&:hover
						@apply text-primary-700
			tr
				&:nth-child(even)
					td
						@apply bg-primary-50
	.filter-table-wrap
		tbody
			tr
				@apply hidden
	.ajax-btn-wrap 
		&.disble-btn
			@apply opacity-0 pointer-events-none