
.news-item-side
	@apply transition bg-white
	@media screen and ( min-width: 400px)
		@apply start-item 
	&:hover
		@apply bg-primary-700
		time
			@apply text-white
		.headline
			a
				@apply text-white
	.headline
		a
			+line(3)
	.img
		flex: 1 0 auto
		a
			@apply overflow-hidden  center-item mr-5 rounded-2
			@media screen and ( min-width: 400px)
				width: r(200px)
				min-width: r(200px)
				max-width: r(200px)
				height: r(199px)
			@screen sm
				width: r(255px)
				min-width: r(255px)
				max-width: r(255px) 
			@media screen and ( max-width: 400px)
				+img-ratio(170,255)

			img
				width: 100%
				height: 100%
				object-fit: cover
	time
		@apply transition
	.txt
		flex: 0 1 auto
		@apply pr-4
		@media screen and ( max-width: 400px)
			@apply pt-5
.news-big
	.img 
		a
			+img-ratio(356,640)
	
	.desc,.headline a
		+line(3)
	&:hover
		.headline
			a
				@apply text-primary-700
.news-item
	.img
		a
			+img-ratio(170,305)
	.head-title
		a
			+line(2)
	&:hover
		.head-title
			a
				@apply text-primary-700
.news-lasted
	@apply relative
	.container
		@apply relative z-30
	&::after
		content: ''
		@apply block absolute pointer-events-none right-0 bottom-[40px] z-10  transition
		height: r(340px)
		width: r(600px)
		background: url('../img/overlay/news-bg.png')
		background-repeat: no-repeat
		background-size: contain
		background-position: right bottom
		@media screen and ( max-width: 1024px)
			height: r(140px)
			width: r(400px)