@layer components
	.desc,.zone-desc
		@apply text-clamp-18 leading-[1.4] 
		*
			@apply text-clamp-18 
		strong
			@apply font-bold
			*
				@apply font-bold  
		ul,ol
			list-style-type: none
			padding-left: 0
			li
				@apply mb-1 relative start-item
				align-items: flex-start
				&::before
					content: ''
					@apply block mt-3 pointer-events-none z-10  transition w-[6px] h-[6px] bg-green-400 rounded-full mr-3 min-w-[6px] 
			ol
				@apply pl-3 pt-1
				li
					&::before
						content: ''
						@apply w-[6px] h-[1px] rounded-full mr-3 min-w-[6px]
	.zone-desc
		@apply font-medium text-clamp-20
		*
			@apply font-medium text-clamp-20

.fullcontent
	@apply font-light leading-normal text-clamp-16
	*
		@apply font-light leading-normal text-clamp-16
	img,iframe
		@apply block mx-auto my-4  overflow-hidden rounded-4
	p
		@apply mb-5 text-clamp-16
		*
			@apply text-clamp-16
	ul,ol
		@apply pl-5 mb-5
	ul
		list-style-type: disc
	ol
		list-style-type: decimal
	hr
		@apply block my-4
	h2,h3,h4,h5,h6
		@apply  mb-4 font-bold 
	strong
		font-weight: 700 !important
		*
			font-weight: 700 !important
	h2
		@apply text-clamp-32
	h3
		@apply text-clamp-28
	h4
		@apply text-clamp-24
	h5
		@apply text-clamp-20
	h6
		@apply text-clamp-18
	.row
		@apply mb-0
	a
		@apply text-primary-900 font-bold
		*
			@apply text-primary-900 font-bold
		&:hover
			@apply underline
	.table-wrap
		@media screen and ( max-width: 1024px)
			@apply overflow-x-auto
			table
				@apply whitespace-nowrap
	table
		@apply overflow-hidden w-full mb-5
		thead
			th
				@apply bg-primary-700 font-bold text-grey-333 text-center py-2 px-4 text-clamp-16  border border-grey-200
		tbody
			td
				@apply text-clamp-16 px-4 py-2 bg-white border border-grey-200
				*
					@apply text-clamp-16
				a
					&:hover
						@apply text-primary-700
			tr
				&:nth-child(even)
					td
						@apply bg-primary-50