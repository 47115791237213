.about-factory
	.zone-nav
		@media screen and ( max-width: 1024px)
			+res-nav
	.block-title
		@apply mb-3
	.scrollbar-wrap
		max-height: r(240px)
		@screen lg
			max-height: r(220px)
	.box-wrap
		@apply py-10
		@media screen and ( max-width: 1024px)
			@apply col-ver
		@screen lg
			@apply py-15
		&:nth-child(even)
			@apply bg-grey-50 bg-opacity-50
			
			.row
				@screen lg
					@apply flex-row-reverse
			.txt
				@screen lg
					@apply pr-0 pl-10
		.col
			@media screen and ( max-width: 1024px)
				@apply order-2
				&:nth-child(2)
					@apply order-1
	.img
		a
			+img-ratio(480,640)