.contact-1
	.item
		box-shadow: 0px 4px 12px 0px #00000014
		@apply p-5 text-center h-full bg-white
		@screen xl
			@apply p-8
	.icon
		@apply center-item mb-4
		height: 40px
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain
	.desc
		@apply text-clamp-15
		word-break: break-word
		*
			@apply text-clamp-15
.contact-2
	@apply bg-green-50 bg-opacity-50
	.scrollbar-wrap
		max-height: r(320px)
		ul,ol
			list-style-type: none
			@apply pt-5
			@screen xl
				@apply pt-7
			li
				@apply mb-2 relative start-item
				&::before
					content: ''
					@apply block mt-1 pointer-events-none z-10  transition w-[9px] h-[9px] bg-green-400 rounded-full mr-3 min-w-[9px] static
	.img
		a
			+img-ratio(480,640)
.contact-3
	.scrollbar-wrap
		@screen lg
			height: r(480px)
			max-height: r(480px) 
	.map-wrapper
		@apply rounded-4 overflow-hidden
.dealer-list
	address
		@apply not-italic
	.scrollbar-wrap
		height: r(480px)
		@apply pr-4
		@screen xl
			@apply pr-8
	.item
		@apply mb-4 transition
		li
			@apply start-item text-clamp-16 font-normal 
			align-items: flex-start
			&:not(:last-child)
				@apply mb-2
			*
				@apply text-clamp-16
		span,p
			@apply start-item my-auto
			a
				@apply ml-1
		em
			@apply text-clamp-20 mr-4 text-primary-700 transition w-[20px]
			@screen xl
				@apply mr-5
		a
			@apply center-item transition 
			span
				@apply text-primary-700 transition
		.headline
			@apply transition
		&.is-active
			@apply bg-primary-700 text-white
			.headline
				@apply text-white
			em
				@apply text-white
			a
				span
					@apply text-white
.map-wrapper 
	@apply h-full
	a
		display: block
		height: r(480px)
		@apply overflow-hidden
		text-align: center
		width: 100%
		@media screen and ( max-width: 1024px)
			height: 400px
		@media screen and ( max-width: 576px)
			height: 300px
		iframe,img
			@apply inline-block w-full h-full
		img
			@apply hidden
	&.image-placeholder
		a
			iframe
				@apply hidden
			img
				@apply block
				object-fit: contain
.contact-4


	.toggle-item
		&.is-toggle
			.title
				em
					transform: none !important
					&:before 
						@apply text-white
						content: "\f068"
		.article
			@apply px-0 py-3 text-white
			*
				@apply text-white
	address
		@apply not-italic text-white
		*
			@apply text-white
		ul,ol
			padding-left: 0 !important
		li
			@apply start-item text-clamp-16 font-normal  text-white
			align-items: flex-start
			&:not(:last-child)
				@apply mb-2
			*
				@apply text-clamp-16
		span
			@apply start-item my-auto 
			a
				@apply ml-1
		em
			@apply text-clamp-20 mr-4 text-white transition
			@screen xl
				@apply mr-5
		a
			@apply center-item transition
			span
				@apply text-white transition
	.wrap-form
		.desc
			@apply mb-7
		.form-group
			@apply rounded-2 overflow-hidden
		.frm-btnwrap 
			@media screen and ( max-width: 1200px)
				@apply mt-5
			@screen xl
				@apply absolute bottom-0 right-0
			.frm-btn 
				input[type='submit']
					@apply rounded-2
	.frm-captcha 
		@media screen and ( max-width: 576px)
			@apply mt-5
		.rcRefreshImage:before
			right: -30px
			@media screen and ( max-width: 576px)
				height: 40px
				right: -5px
	.toggle-wrap
		@apply bg-primary-700 overflow-hidden rounded-2 p-6 
		@screen xl
			@apply p-8
.toggle-item
	@apply overflow-hidden   overflow-hidden
	&.is-toggle
		.title
			span
				-webkit-line-clamp: unset
				@apply text-white
				&:before 
					@apply text-white
			em
				@apply text-white
				transform: scaleY(-1)
					
		.article
			@apply block
	.title
		@apply gap-item transition py-2 border-b border-b-primary-600
		min-height: 60px
		@screen lg
			@apply py-3 
			min-height: 60px
		p
			@apply text-clamp-18 font-bold leading-none text-white mb-0
			*
				@apply text-clamp-18 font-bold leading-none text-white
		em
			@apply  ml-5 inline-block text-white
			font-size: 20px
			height: 20px
			width: 20px
			transition: .3s all  ease-in-out
	.article
		@apply hidden text-clamp-16 font-normal p-5 leading-normal
		*
			@apply  text-clamp-16 font-normal leading-normal
		p
			@apply block mb-4
		ul
			@apply mt-4
			padding-left: 20px
			list-style-type: disc
			li
				@apply mb-2
		strong,h3,h4
			@apply font-bold
		img
			@apply block mx-auto my-4
		ul
			list-style-type: disc
			li
				@apply mb-2
