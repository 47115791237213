.factory-detail
	ul,ol
		li
			&::before
				@apply hidden
	.dash
		@apply block my-4 border-transparent
		background: #D9D9D9 !important
	.briefcontent
		ul,ol
			@apply flex justify-between flex-wrap
		li
			@apply start-item text-clamp-18 font-normal py-3  w-full
			@screen md
				@apply w-1/2
			*
				@apply text-clamp-18 font-normal
			em
				@apply center-item text-white bg-primary-700 overflow-hidden rounded text-clamp-32 mr-3
				width: r(60px)
				height: r(60px)
				min-width: r(60px)

	.img
		a
			+img-ratio(984,1312)