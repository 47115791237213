.about-culture,.about-culture-2
	.scrollbar-wrap
		max-height: r(180px)
	.headline
		@apply bg-gradient mb-4
	.img
		a
			+img-ratio(480,640)
	.txt
		@apply pt-10
		@screen lg
			@apply pr-15 py-0
	.box-wrap
		.row
			@media screen and ( max-width: 1024px)
				@apply flex-col-reverse  m-0 p-0
				.col
					@apply m-0 p-0
		&:nth-child(even)
			@apply bg-grey-50 bg-opacity-50
			.txt
				@screen lg
					@apply pl-15 pr-0
			.row
				@screen lg
					@apply flex-row-reverse
	.block-title
		@apply text-center
		@screen lg
			@apply text-left
.about-culture-2
	.box-wrap
		@apply py-0 
		@screen lg
			@apply mb-10
		&:nth-child(even)
			@apply bg-white
		@media screen and ( max-width: 1024px)
			&:not(:last-child)
				@apply mb-10
	.block-title
		@apply text-center mb-4