.dealer-network
	.bg-wrap
		@apply bg-grey-50 bg-opacity-50
	.zone-desc
		@apply text-center text-clamp-18
		*
			@apply text-clamp-18
	.dealer-list
		height: r(620px)
		max-height: r(620px)
	.map-wrapper
		a
			height: r(620px)
	.zone-nav
		@apply w-full
		ul
			@apply w-full
			justify-content: center !important
		
.dealer-item
	box-shadow: 0px 4px 12px 0px #00000014
	.icon
		display: flex
		height: r(48px)
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain