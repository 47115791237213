.tiem-luc-page
	.about-list
		.zone-nav
			@media screen and ( max-width: 1024px)
				@apply w-full
				ul
					@apply w-full
					justify-content: center !important
.sticky-nav
	&.scroll-to-fixed-fixed
		z-index: 120
		@media screen and ( max-width: 1200px)
			top: 60px !important
		@screen xl
			top: 100px !important
.about-list
	.zone-nav
		@media screen and ( max-width: 1024px)
			+res-nav
	.block-title
		@apply mb-3
	.scrollbar-wrap
		max-height: r(240px)
		@screen lg
			max-height: r(220px)
	&.even
		.box-wrap
			&:nth-child(odd)
				@apply bg-white
			&:nth-child(even)
				@apply bg-grey-50 bg-opacity-50
	.box-wrap
		@apply py-10
		@media screen and ( max-width: 1024px)
			@apply col-ver
		@screen lg
			@apply py-15
		&:nth-child(odd)
			@apply bg-grey-50 bg-opacity-50
		&:nth-child(even)
			
			.row
				@screen lg
					@apply flex-row-reverse
			.txt
				@screen lg
					@apply pr-0 pl-10
		.col
			@media screen and ( max-width: 1024px)
				@apply order-2
				&:nth-child(2)
					@apply order-1
	.img
		a
			+img-ratio(480,640)
		&.earth
			img
				object-fit: contain
	.headline
		@apply py-3 px-8 bg-primary-700 mt-3

.tab-content
	@apply hidden
	&.active
		@apply block