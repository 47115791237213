.filter-toggle
	@apply  w-[40px] h-[40px] top-1/2 right-[15px] hidden
	@media screen and ( max-width: 1024px)
		@apply right-[5px] center-item
	&:hover
		@apply bg-white
		em
			@apply text-primary-700
.mobile-filter-wrap
	@apply bg-white py-6
	@media screen and ( max-width: 1024px)
		@apply fixed top-[60px] left-0  w-full h-full bg-white p-8 pt-8 opacity-0 pointer-events-none transition border-t border-t-primary-100 overflow-y-auto
		z-index: 90
	.container
		@apply p-0
	&.is-open
		@apply opacity-100 pointer-events-auto
	.close-filter
		@apply hidden
		@media screen and ( max-width: 1024px)
			@apply center-item h-[40px] bg-red overflow-hidden rounded font-medium  absolute-x bottom-[80px] 
			width: 96%
		span
			@apply text-white  text-clamp-18
	.filter-item,.frm-btn
		@apply w-full
		button
			@apply w-full
	.filter-wrap
		h2
			@apply text-clamp-20 font-semibold text-primary-700 mr-4 whitespace-nowrap
			@media screen and ( max-width: 1024px)
				@apply hidden

.filter-wrap
	@screen lg 
		@apply center-item
	@media screen and ( max-width: 1024px)
		@apply col-ver
	.filter-item
		@apply start-item
		@screen lg
			@apply  mr-3
		@media screen and ( max-width: 1024px)
			@apply col-ver mb-6 w-full
		select,input
			@apply w-full  h-[48px] bg-primary-EAF2F6 rounded-full overflow-hidden 
		
			&::placeholder
				@apply text-grey-500 text-clamp-16
		input[type='date'], input[type='time'] 
			display: block
			-webkit-appearance: none
			margin-top: 0px
			text-align: center
			align-self: center
			vertical-align: bottom
		input::-webkit-datetime-edit 
			display: block
			padding: 0
		input::-webkit-date-and-time-value
			@apply pt-[12px]
			text-align: center
			align-self: bottom
			vertical-align: bottom
	.frm-btn
		input[type='submit'],button
			@apply bg-primary-700 h-[48px] rounded-full overflow-hidden center-item px-6 text-white text-clamp-16 border border-primary-700 transition
			&:hover
				@apply bg-white text-primary-700
		@media screen and ( max-width: 1024px)
			@apply w-full
			input[type='submit'],button
				@apply w-full
.filter-check-list
	.scrollbar-wrap
		@screen lg
			@apply h-auto 
		@media screen and ( max-width: 1024px)
			height: 200px
	li
		&:first-child
			a
				@apply pt-0
	a
		@apply text-clamp-20 font-normal py-3 relative start-item
		align-items: flex-start
		&:before
			content: '\f0c8'
			height: 20px
			width: 20px
			transition: .3s all ease-in-out
			@apply font-Awesome6 inline-block pointer-events-none z-20 transition mr-2 mt-[2px] text-grey-500
		&:hover
			@apply text-dark 
	.active
		a
			&:before
				content: '\f14a'
				@apply font-black text-green-400