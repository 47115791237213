//Type 1 first child icon
.modulepager,.post-nav
	@apply flex items-center justify-center pt-8 pb-0
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			&.active
				a,span
					@apply text-white
					background: #8CC241
			&:hover
				a,span
					@apply text-white
					background: #8CC241
			
			span,a
				transition: .3s all  ease-in-out
				display: flex
				justify-content: center
				align-items: center
				width: 40px
				height: 40px
				text-decoration: none
				@apply text-primary-700 text-clamp-18  font-normal rounded-full overflow-hidden border border-transparent
				background: #D5EDF8
		.next
			a
				width: max-content
				@apply whitespace-nowrap
.ajaxresponse-pager-wrap
	@apply center-item