.zone-item
	@apply h-full
	background:  var(--color-code)
	.img
		
		a
			@apply center-item p-8
			height: r(120px)
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain
	.headline
		@apply center-item text-center
		*
			@apply text-white
	.headline 
		@apply pb-4  px-4
.search-product
	.product-figure
		.desc
			li
				list-style: none
				@apply py-4 text-clamp-18 
				*
					@apply text-clamp-18 
				p
					@apply mb-0
				>div
					@apply block
				&:not(:last-child)
					@apply border-b border-b-grey-200
				&:first-child
					@apply pt-0
					>div
						>div
							&:first-child
								@apply text-green-400
				>div
					>div
						&:first-child
							@apply text-primary-700 mb-3 block font-normal
	.section-title
		@apply text-clamp-40 font-light text-dark 
		margin-bottom: r(40px)
		*
			@apply text-clamp-40 font-light text-dark 
		@screen lg
			margin-bottom: r(80px) 
		@screen xl
			margin-bottom: r(120px)
		@screen 2xl
			margin-bottom: r(180px)
	.product-search
		.form-group
			@apply start-item

.alphabetical-filter
	@apply flex
	.tab-nav
		ul
			@apply col-ver overflow-x-hidden
			height: r(420px)
		.active
			a
				@apply text-clamp-20 font-bold text-primary-700
		a
			@apply text-clamp-16 mb-6 uppercase block
			@screen xl
				@apply mb-4
	.tab-item
		ul
			height: r(420px)
		li
			&:not(:last-child)
				@apply mb-7
		.headline
			&:hover
				@apply underline
.product-figure
	@apply end-item
	.img
		@apply p-6
		@screen xl
			@apply p-8
		a
			+img-ratio(1,1)