.media-item
	.img
		a
			+img-ratio(170,305)
		&.video
			@apply relative
			&::after
				content: ''
				@apply block absolute-center pointer-events-none  z-10  transition 
				background: url('../img/icon/play.png')
				background-repeat: no-repeat
				background-size: contain
				background-position: center
				width: r(60px)
				height: r(60px)
	.head-title
		a
			+line(2)