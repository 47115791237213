.wrap-form
	@apply relative
	.row
		box-shadow: none !important
	
	.col
		@apply mb-4 relative
		&:last-child
			@apply mb-6
	.form-group
		@apply relative 
		>span
			@apply w-full
		input[type='text'],textarea,select,input[type='email'],input[type='tel']
			height: 48px
			@apply  bg-transparent border border-grey-100 text-clamp-16  px-5 border-opacity-20 bg-white w-full
			@media screen and ( max-width: 1200px)
				height: 40px
			&:focus
				@apply border-primary-100
			&::placeholder
				@apply  font-normal text-clamp-16  text-grey-950
	.form-group
		&.form-select
			@apply relative overflow-hidden
			&::after
				content: ''
				@apply block absolute-y pointer-events-none right-[35px]  z-10  transition
				width: r(24px)
				height: 11px
				background-size: contain
				background-repeat: no-repeat
				background-position: center
				background-image: url("data:image/svg+xml;charset=utf-8,<svg width='23' height='11' viewBox='0 0 23 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.1875 0.53125C1.32812 0.34375 1.51562 0.25 1.75 0.25C1.89062 0.25 2.07812 0.296875 2.21875 0.4375L11.4531 8.92188L20.7344 0.4375C21.0156 0.15625 21.4844 0.15625 21.7656 0.484375C22.0469 0.765625 22.0469 1.23438 21.7188 1.51562L11.9688 10.5156C11.6875 10.7969 11.2656 10.7969 10.9844 10.5156L1.23438 1.51562C0.90625 1.28125 0.90625 0.8125 1.1875 0.53125Z' fill='%232F4394'/></svg>")
				@media screen and ( max-width: 1200px)
					width: 16px
					height: 11px
					height: 40px
			select
				height: 44px
				@apply rounded-none w-full  text-grey-333 
				padding-right: 50px !important
				-webkit-appearance: none
				-moz-appearance: none
				-webkit-box-sizing: border-box
				-moz-box-sizing: border-box
				box-sizing: border-box
				-webkit-appearance: none
				-moz-appearance: none
				font-size: clamp(13px,r(14px),14px)
				@media screen and ( max-width: 1200px)
					font-size: clamp(14px,r(16px),16px)
					height: 40px
				option
					font-size: clamp(13px,r(14px),14px)
					@apply  text-grey-333
					@media screen and ( max-width: 1200px)
						font-size: clamp(14px,r(16px),16px)
				&::-ms-expand 
					display: none
		textarea
			@apply p-4 w-full
			height: 7rem
			@screen lg
				height: 120px
	.qradio
		@apply relative
		input[type='radio']
			@apply absolute-center w-full h-full z-20 opacity-0
		label
			@apply pointer-events-none z-10
	.frm-btnwrap
		@apply start-item  mt-0 w-fit
		@media screen and ( max-width: 1200px)
			margin-bottom: 0 !important
		label
			display: none !important
		.frm-btn
			@apply relative  mt-0 bg-primary-700 overflow-hidden rounded
			&:hover
				&::after
					@apply text-primary-700
				input[type='submit']
					@apply  text-primary-700 bg-white
			&::after
				font-family: 'icomoon' !important
				content: "\e900"
				@apply block absolute pointer-events-none top-50 translate-y-50 right-[35px]  z-10  transition text-white  font-light  
				font-size: 8px
				height: 18px
				width: 10px
				line-height: 18px
			input[type='submit']
				width: 120px
				height: 48px
				@apply transition text-white font-normal center-item px-6 pr-12 overflow-hidden  border bg-primary-700  rounded border-primary-700 text-clamp-16
				@media screen and ( max-width: 1024px) 
					height: 40px
					@apply px-3  pr-8
.fa-exclamation-triangle
	@apply absolute text-red font-bold
	font-family: 'Open Sans', sans-serif !important
	font-size: 12px
	bottom: -23px
	left: 0
	@media screen and ( max-width: 576px)
		font-size: 10px
		@apply font-bold
	&:before
		font-family: 'Font Awesome 6 Pro' !important
.input-group,.form-password
	@apply relative
	.reveal-password
		@apply absolute top-50 right-[20px] -translate-y-1/2
		em
			@apply font-light
			font-size: 14px
.pwd-input-group
	input
		@apply w-full
	&.show
		.reveal-password
			em
				&::before
					content: '\f06e' !important
.frm-btn-reset
	display: none
.frm-captcha
	margin: 30px 0 0  0 !important
	display: inline-flex
	flex-direction: row-reverse
	img
		height: 44px !important
		max-width: auto
		max-width: 150px
		@media screen and ( max-width:1200px)
			max-width: 150px
			
	@media screen and (max-width:576px)
		flex-direction: column-reverse
	.frm-captcha-input
		@apply mt-0
		margin-left: 50px
		@media screen and ( max-width:576px)
			margin-left: 0
			@apply mt-4
		label
			display: none
		input
			padding: 0 10px
			margin-top: 0
			height: 30px
			@media screen and ( max-width:1200px)
				max-width: 200px
			@media screen and ( max-width:1200px)
				max-width: 150px
				
			@media screen and ( max-width:1024px)
				max-width: 200px
				
			@media (max-width: 767.98px)
				margin-left: 20px
			@media (max-width: 576.98px)
				margin-left: 0
	.rcRefreshImage
		position: absolute
		opacity: 1
		cursor: pointer
		height: 0
		width: 0
		right: 0
		top: 5px
		color: #ffffff
		z-index: 0
		font-size: 0
		&:before
			font-family: 'Font Awesome 6 Pro'
			position: absolute
			top: 0
			right: -40px
			opacity: 1
			z-index: 99
			color: #9d9080
			font-size: 30px
			content: "\f01e"
			display: flex
			justify-content: center
			align-items: flex-start
			@media screen and ( max-width:576px)
				height: 50px
@media screen and ( max-width:500px )
	#ctl00_mainContent_ctl05_captcha_ctl00
		display: none
#ctl00_mainContent_ctl02_captcha_ctl01
	position: relative
#ctl00_mainContent_ctl06_btnReset,#ctl00_mainContent_ctl05_captcha_ctl00,#ctl00_mainContent_ctl08_captcha_ctl00,#ctl00_mainContent_ctl07_captcha_ctl00
	display: none
#ctl00_mainContent_ctl06_txtCaptcha
	margin-left: 10px
#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton
	#text
		display: none
.RadCaptcha
	@apply relative
	@media screen and ( max-width:767.5px)
		margin-left: 0
	span
		@apply hidden
	#ctl00_mainContent_ctl04_captcha_ctl00
		display: block !important
		position: absolute
		left: 0
		top: 100%
		@apply text-red text-12
	>div
		>div
			display: flex
			position: relative
			flex-direction: column-reverse
			height: 40px !important
			margin-top: 0
			@media (max-width: 767.98px)
				margin-right: 0
				width: 180px
