.about-history
	.block-wrap
		@apply relative mt-8
		@screen md
			&::after
				content: ''
				@apply block absolute-x pointer-events-none bottom-0 z-10 transition h-full bg-grey-300
				width: 2px
	.row
		@apply relative mb-0
		@media screen and ( max-width: 768px)
			@apply m-0 p-0 mb-8
			.col
				@apply m-0 p-0
		@screen lg
			@apply  mb-8
		.txt
			@apply transition
			@screen md
				@apply mr-14
		&:nth-child(even)
			.wrap-right
				@screen md
					@apply justify-start
				.btn
					@apply pl-2
			.txt
				@screen md
					@apply ml-14 mr-0
			@screen md
				.years
					@apply ml-auto
				@apply flex-row-reverse
		@screen md
			&::before
				content: ''
				@apply block absolute-x pointer-events-none top-0 z-30  transition w-[16px] h-[16px] bg-grey-300 rounded-full
			&::after
				content: ''
				@apply block absolute-x pointer-events-none top-0 z-20  transition w-[2px] h-full bg-green-400 opacity-0
	
	.years
		@apply text-clamp-32 font-bold text-grey-500 leading-none mb-4 transition
		@screen xl
			@apply text-clamp-40
	.item-year
		@apply hidden
		@media screen and ( max-width: 768px)
			.years
				@apply text-clamp-48 text-primary-700 
			.txt
				@apply bg-primary-50 p-6
		.wrap-right
			@media screen and ( max-width: 768px)
				@apply justify-start
		.headline
			@apply text-grey-950
		&:hover
			.headline
				@apply text-primary-700
			@screen md
				&::before
					@apply bg-primary-700
					height: 20px
					width: 20px
					border: 2px solid #fff
					box-shadow: 0px 0px 0px 1px #8CC241
				&::after
					@apply opacity-100
			@screen lg
				&::before
					@apply bg-primary-700
					height: 40px
					width: 40px
					border: 5px solid #fff
					box-shadow: 0px 0px 0px 1px #8CC241
			.years
				@apply text-clamp-48 text-primary-700
				@screen xl
					@apply text-clamp-64
					height: 64px
			.txt
				@apply bg-primary-50 p-6
				@screen xl
					@apply p-8
.rm-wrap
	&.active
		.article
			@apply block
		.btn-read-more
			.txt-2
				@apply block
			.txt-1
				@apply hidden
	.article
		@apply hidden
	.btn-read-more
		@apply py-1
		span,em
			@apply text-grey-950
		span
			@apply font-normal
		.txt-2
			@apply hidden
		&:hover
			span
				@apply underline
			em
				transform: none !important
.read-more-wrap
	&.active
		.article
			height: auto !important
		.btn-read-more
			.txt-2
				@apply block
			.txt-1
				@apply hidden
	.article
		@apply overflow-hidden
		ul,ol
			list-style-type: none
			padding-left: 0
			li
				@apply mb-1 relative start-item
				align-items: flex-start
				&::before
					content: ''
					@apply block mt-2 pointer-events-none z-10  transition w-[4px] h-[4px] bg-dark rounded-full mr-3 min-w-[4px] 
	.btn-read-more
		@apply pt-5 pb-1
		span,em
			@apply text-primary-700
		span
			@apply font-normal
		.txt-2
			@apply hidden
		&:hover
			span
				@apply underline
			em
				transform: none !important
.about-achivement
	.row,.col
		@apply m-0 p-0
	.year
		@apply text-primary-600 font-bold text-clamp-24 w-fit mx-auto text-center  border-b-2 border-transparent transition
		@media screen and ( max-width: 1024px)
			@apply center-item h-full py-5 text-clamp-32
	.vertical-swiper
		@apply my-auto
		@screen lg
			height: r(300px) !important
		.swiper
			@screen lg
				height: r(300px) !important
		.swiper-slide
			height: auto !important
			.year
				@apply opacity-30
			&.swiper-slide-prev,&.swiper-slide-next
				.year
					@apply opacity-100
			&.swiper-slide-active
				.year
					@apply text-white  opacity-100 border-b-green-400
					@screen lg
						@apply text-clamp-40  py-3
	.prev,.next
		height: r(40px)
		width: r(40px)
		min-width: r(40px)
		em
			@apply text-clamp-20
	.prev
		@apply left-[5px]

	.next
		@apply right-[5px]
	.txt
		@media screen and ( max-width: 1024px)
			@apply p-8
		@media screen and ( max-width: 576px)
			@apply p-6
	.swiper-nav
		@apply -mx-2 px-2 center-item absolute bottom-[10px] 
		left: 24%
		@media screen and ( max-width: 1024px)
			@apply hidden
	.img
		a
			+img-ratio(525,630)
	.swiper-button-disabled
		@apply opacity-100
.tab-item-swiper
	@apply hidden
	&.active
		@apply block